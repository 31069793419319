import React from 'react'

const Practice = () => {
  return (
    <div>
      
    </div>
  )
}

export default Practice
